import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useInterval } from '@southport-technology-group/react-common';
import { RequestStatus } from '../../common/types';
import { ISpreadsheetFormData } from '../../containers/Home';
import { useCustomers, useLoadCustomers } from '../../state/modules/customers';
import {
  useLoadSiteMonitorClasses,
  useSiteMonitorClasses,
} from '../../state/modules/siteMonitorClasses';
import { useLoadSites, useSites } from '../../state/modules/sites';
import { isDefined, isUndefined } from '../../utils/typeGuards';
import { ScreenLoader } from '../common/Loaders';
import { displayAlerts } from '../helpers/displayAlerts';
import {
  IMonitorDataImportJob,
  useLoadMonitorDataImportJobs,
  useMonitorDataImportJobs,
} from '../../state/modules/monitorDataImportJobs';
import { ImportJobStatusAlert } from './ImportJobStatusAlert';

interface IImportSpreadsheetFormDataPropTypes {
  onSubmit: (data: ISpreadsheetFormData) => void;
}

export function ImportSpreadsheetForm({
  onSubmit,
}: IImportSpreadsheetFormDataPropTypes) {
  const customers = useCustomers();
  const sites = useSites();
  const siteMonitorClasses = useSiteMonitorClasses();
  const monitorDataImportJobs = useMonitorDataImportJobs();
  const loadCustomers = useLoadCustomers();
  const loadSites = useLoadSites();
  const loadSiteMonitorClasses = useLoadSiteMonitorClasses();
  const loadMonitorDataImportJobs = useLoadMonitorDataImportJobs();

  const [relatedCustomer, setRelatedCustomer] = useState<number>();
  const [relatedSite, setRelatedSite] = useState<number>();
  const [relatedSiteMonitorClass, setRelatedSiteMonitorClass] =
    useState<number>();
  const [headerRow, setHeaderRow] = useState(1);
  const [selectedFile, setSelectedFile] = useState<File>();

  useEffect(() => {
    loadCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRelatedSite(undefined);
    if (isDefined(relatedCustomer)) {
      loadSites({ relatedCustomer });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedCustomer]);

  useEffect(() => {
    setRelatedSiteMonitorClass(undefined);
    if (isDefined(relatedSite)) {
      loadSiteMonitorClasses({ relatedSite });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedSite]);

  useInterval(
    () => {
      loadMonitorDataImportJobs();
    },
    { callImmediately: true, interval: 7500 }
  );

  function handleRelatedCustomerChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = +e.target.value;
    setRelatedCustomer(isNaN(value) ? undefined : value);
  }

  function handleRelatedSiteChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = +e.target.value;
    setRelatedSite(isNaN(value) ? undefined : value);
  }

  function handleRelatedSiteMonitorClassChange(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const value = +e.target.value;
    setRelatedSiteMonitorClass(isNaN(value) ? undefined : value);
  }

  function handleHeaderRowChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = +e.target.value;
    setHeaderRow(value);
  }

  function hanldeSelectedFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files![0];
    setSelectedFile(file);
  }

  function handleSubmit() {
    const customer = customers.payload.find(
      (customer) => customer.recordId === relatedCustomer
    );
    const site = sites.payload.find((site) => site.recordId === relatedSite);
    const siteMonitorClass = siteMonitorClasses.payload.find(
      (siteMonitorClass) =>
        siteMonitorClass.recordId === relatedSiteMonitorClass
    );

    if (customer && site && siteMonitorClass && headerRow && selectedFile) {
      onSubmit({
        customer,
        site,
        siteMonitorClass,
        headerRow,
        file: selectedFile,
      });
    }
  }

  const customerOptions = useMemo(() => {
    return [
      <option key="">Select Customer</option>,
      ...customers.payload
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((customer) => (
          <option key={customer.recordId} value={customer.recordId}>
            {customer.name}
          </option>
        )),
    ];
  }, [customers.payload]);

  const sitesOptions = useMemo(() => {
    return [
      <option key="">Select Site</option>,
      ...sites.payload
        .sort((a, b) => (a.siteName < b.siteName ? -1 : 1))
        .map((site) => (
          <option key={site.recordId} value={site.recordId}>
            {site.siteName}
          </option>
        )),
    ];
  }, [sites.payload]);

  const siteMonitorClassesOptions = useMemo(() => {
    return [
      <option key="">Select Monitor Class</option>,
      ...siteMonitorClasses.payload
        .sort((a, b) => (a.monitorClass < b.monitorClass ? -1 : 1))
        .map((siteMonitorClass) => (
          <option
            key={siteMonitorClass.recordId}
            value={siteMonitorClass.recordId}
          >
            {siteMonitorClass.monitorClass}
          </option>
        )),
    ];
  }, [siteMonitorClasses.payload]);

  const httpErrors =
    customers.httpErrors || sites.httpErrors || siteMonitorClasses.httpErrors;

  const submitDisabled =
    isUndefined(relatedCustomer) ||
    isUndefined(relatedSite) ||
    isUndefined(relatedSiteMonitorClass) ||
    isUndefined(selectedFile);

  return (
    <Form>
      {displayAlerts(
        httpErrors && httpErrors.errors && httpErrors.errors.length !== 0
          ? httpErrors.errors // Display httpErrors
          : [httpErrors?.message] // Fall back to error.message if no errors[] provided
      )}
      {customers.status === RequestStatus.LOADING ? (
        <ScreenLoader />
      ) : (
        <>
          <Form.Group className="mb-3" data-test-id="upload-spreadsheet-form">
            {!!(
              monitorDataImportJobs?.payload as unknown as IMonitorDataImportJob
            )?.processing && (
              <ImportJobStatusAlert
                monitorDataImportJob={
                  monitorDataImportJobs?.payload as unknown as IMonitorDataImportJob
                }
              />
            )}
            <Form.Label>Customer</Form.Label>
            <Form.Control
              name="relatedCustomer"
              autoComplete="off"
              as="select"
              value={relatedCustomer}
              onChange={handleRelatedCustomerChange}
              disabled={customers.status !== RequestStatus.SUCCEEDED}
              data-test-id="related-customer"
            >
              {customerOptions}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Site</Form.Label>
            {sites.status === RequestStatus.LOADING ? (
              <ScreenLoader />
            ) : (
              <Form.Control
                name="relatedSite"
                autoComplete="off"
                as="select"
                value={relatedSite}
                onChange={handleRelatedSiteChange}
                disabled={
                  sites.status !== RequestStatus.SUCCEEDED ||
                  isUndefined(relatedCustomer)
                }
                data-test-id="related-site"
              >
                {sitesOptions}
              </Form.Control>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Monitor Class</Form.Label>
            {siteMonitorClasses.status === RequestStatus.LOADING ? (
              <ScreenLoader />
            ) : (
              <Form.Control
                name="relatedSiteMonitorClass"
                autoComplete="off"
                as="select"
                value={relatedSiteMonitorClass}
                onChange={handleRelatedSiteMonitorClassChange}
                disabled={
                  siteMonitorClasses.status !== RequestStatus.SUCCEEDED ||
                  isUndefined(relatedSite)
                }
                data-test-id="related-monitor-class"
              >
                {siteMonitorClassesOptions}
              </Form.Control>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Header Row</Form.Label>
            <Form.Control
              type="number"
              value={headerRow}
              onChange={handleHeaderRowChange}
              placeholder="Enter header row"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              onChange={hanldeSelectedFileChange}
              type="file"
              data-test-id="choose-file"
            />
          </Form.Group>
          <Button
            block
            variant="primary"
            type="submit"
            disabled={submitDisabled}
            onClick={handleSubmit}
            data-test-id="continue-to-match-btn"
          >
            Continue To Match
          </Button>
        </>
      )}
    </Form>
  );
}
